@import "../../global/mixins";

.guidelinesPage,
.fgdPage {
    margin-top: calc(var(--nav-height));
    padding: getRem(48) 0 getRem(64);

    .wrapper {
        max-width: getRem(1240);
        display: flex;
    }

    // .row {
    //     display: flex;
    // }

    .btn {
        @include font(16, 28);
        margin: 0;
        padding: getRem(8) getRem(16);
    }

    .contentWrapper {
        width: calc(100% - 273px);
        padding-right: getRem(68);

        @include breakpoint(mobile) {
            width: 100%;
            padding-right: 0;
        }

        h1 {
            margin: getRem(16) 0 0;
            padding-bottom: getRem(24);
        }
    }

    h2 {
        @include font(32, 40);
        // margin: 0 0 getRem(32);
        margin: 0;

        * {
            width: 100%;
            word-break: normal;
            white-space: normal;
        }
    }

    .breadcrumbs {
        li {
            text-transform: none;
            letter-spacing: 1px;
            font-weight: unset;

            &:last-child {
                color: var(--red);
                font-weight: 600;
            }

            &:before {
                color: #535353;
                font-weight: 400;
            }

            a {
                text-transform: none;
                letter-spacing: 1px;

                &:hover {
                    color: var(--red);
                    text-decoration: underline;
                }

                &:focus {
                    outline: 1px solid var(--red);
                }
            }
        }
    }

    .anchorList {
        width: getRem(273);
        max-height: calc(100vh - (var(--nav-height) + 16px) - 32px);
        border-radius: 4px;
        background: var(--white);
        border-top: getRem(4) solid var(--yellow);
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.10);
        margin-top: getRem(48);
        margin-bottom: auto;
        overflow: auto;
        padding: getRem(20) getRem(20) getRem(24);
        position: sticky;
        top: calc(var(--nav-height) + 16px);
        right: 0;

        @include breakpoint(mobile) {
            display: none;
        }

        .row {
            display: flex;
            align-items: center;
            // justify-content: space-between;

            .menuToggle {
                background-color: transparent;
                border: none;
                color: var(--text-color);
                cursor: pointer;
                display: inline-block;
                @include font(16, 18);
                font-weight: 400;
                padding: 0;
                text-align: left;
            }

            .toggleIcon {
                width: getRem(20);
                height: getRem(20);
                background-color: var(--light-grey);
                border: none;
                color: var(--red);
                display: inline-block;
                @include font(16, 20);
                margin-left: getRem(4);
                text-align: center;
            }
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                margin: 0 0 getRem(12);

                &:last-child {
                    margin-bottom: 0;
                }

                a,
                span.linkText {
                    color: var(--text-color);
                    display: inline-block;
                    @include font(16, 18);
                    font-weight: 400;
                }

                a,
                button {
                    cursor: pointer;
                }
            }
        }

        .subMenu {
            display: none;
            margin-top: getRem(16);

            &.show {
                display: block;
            }
        }

        :global {
            .show {
                display: block !important;
            }
        }
    }

    .downloadMenu {
        ul {
            li {
                // max-height: getRem(20);
                display: flex;

                :global {
                    a {
                        display: inline-block;

                        &.active {
                            color: var(--red);
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }

    table {
        border-collapse: collapse;
        border-spacing: 0;

        tbody {
            tr {
                &:first-child {
                    border-top: 2px solid var(--yellow);

                    td {
                        text-align: center;
                    }
                }
            }
        }

        tr {
            border-bottom: 2px solid #e5e5e5;
        }

        td {
            @include font(14, 20);
            padding: getRem(8);
            vertical-align: top;

            &:first-child {
                text-align: left !important;
            }

            &:not(:first-child) {
                border-left: 2px solid var(--yellow);
            }

            * {
                @include font(14, 20);
                vertical-align: top;
            }

            ul {
                &:first-child {
                    margin-top: 0;
                }
            }
        }

        a {
            color: var(--red);
            font-weight: bold;
            text-decoration: underline;
        }
    }

    .cta {
        color: var(--red);
        display: inline-block;
        @include font(16, 24);
        font-weight: 700;

        &:hover {
            text-decoration: underline;
        }

        &:focus {
            outline: 1px solid var(--red);

            &:focus-visible {
                outline: none;
            }
        }
    }
}

// .contentSection {
//     border-bottom: getRem(1) solid #ADADAD;
//     padding: getRem(64) 0;
// }

.divider {
    height: getRem(1);
    width: 100%;
    background: #ADADAD;
    display: block;
	width: 100%;
    //grid-column: span 12;
    margin: getRem(64) 0;
}

.headerSection {
    padding-top: 0;

    .thumbnailContainer {
        margin-top: getRem(56);
    }

    p {
        @include font(32, 40);
        font-weight: 700;
        margin: getRem(56) 0 0;
    }

    &+div:not(.divider-section) {
        // margin-top: getRem(56);
        padding-top: getRem(56);
    }

    &+.section {
        margin-top: getRem(64);
    }
}

.section {
    // display: flex;
    // flex-wrap: wrap;
    //display: grid;
    // grid-gap: getRem(68) getRem(20);
    //grid-gap: getRem(20);
    //grid-template-columns: repeat(12, 1fr);
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	gap: getRem(68) getRem(20);

	:global {
		.has-image-border {

			img {
				border: 1px solid #d6d6d6;
			}
		}
	}

    &>* {
		width: 100%;
        //grid-column: span 12;

        &[data-size="20"] {
            //grid-column: span 2;
			flex: 0 0 calc(20% - getRem(20));

            @include breakpoint(tablet) {
                //grid-column: span 6;
				flex: 0 0 calc(50% - getRem(20));
            }

            @include breakpoint(mobile) {
                //grid-column: span 12;
				flex: 0 0 100%;
            }
        }

        &[data-size="25"] {
            //grid-column: span 3;
			flex: 0 0 calc(25% - getRem(20));

            @include breakpoint(tablet) {
                //grid-column: span 6;
				flex: 0 0 calc(50% - getRem(20));
            }

            @include breakpoint(mobile) {
                //grid-column: span 12;
				flex: 0 0 100%;
            }
        }

        &[data-size="33"] {
            //grid-column: span 4;
			flex: 0 0 calc(33% - getRem(20));

            @include breakpoint(tablet) {
                //grid-column: span 6;
				flex: 0 0 calc(50% - getRem(20));
            }

            @include breakpoint(mobile) {
                //grid-column: span 12;
				flex: 0 0 100%;
            }
        }

        &[data-size="50"] {
            //grid-column: span 6;
			flex: 0 0 calc(50% - getRem(20));

            @include breakpoint(mobile) {
                //grid-column: span 12;
				flex: 0 0 100%;
            }
        }

        &[data-size="66"] {
            //grid-column: span 8;
			flex: 0 0 calc(66% - getRem(20));

            @include breakpoint(tablet) {
                //grid-column: span 6;
				flex: 0 0 calc(50% - getRem(20));
            }

            @include breakpoint(mobile) {
                //grid-column: span 12;
				flex: 0 0 100%;
            }
        }

        &[data-size="75"] {
            //grid-column: span 9;
			flex: 0 0 calc(75% - getRem(20));

            @include breakpoint(tablet) {
                //grid-column: span 6;
				flex: 0 0 calc(50% - getRem(20));
            }

            @include breakpoint(mobile) {
                //grid-column: span 12;
				flex: 0 0 100%;
            }
        }

        &[data-size="80"] {
            //grid-column: span 10;
			flex: 0 0 calc(80% - getRem(20));

            @include breakpoint(tablet) {
                //grid-column: span 6;
				flex: 0 0 calc(50% - getRem(20));
            }

            @include breakpoint(mobile) {
                //grid-column: span 12;
				flex: 0 0 100%;
            }
        }

        &[data-size="100"] {
            //grid-column: span 12;
			flex: 0 0 100%;

            .imageContainer {
                img {
                    width: auto;
                    max-width: 100%;
                }
            }
        }
    }

    img {
		display: block;
        width: 100%;
        max-width: 100%;
    }

    .content {
        width: 100%;
        margin: 0;
    }

    :global {
        .hasBorder {
            outline: 1px solid #E5E5E5;
        }
    }
}

.grid {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	gap: getRem(20);
    width: 100%;
    //grid-column: span 12;
    //display: grid;
    //grid-gap: getRem(20);
    //grid-template-columns: repeat(12, 1fr);

    &>* {
        //grid-column: span 6;
		width: calc(50% - getRem(20));
        //margin: auto 0;

        &[data-size="20"] {
            //grid-column: span 2;
			flex: 0 0 calc(20% - getRem(20));

            @include breakpoint(tablet) {
                //grid-column: span 6;
				flex: 0 0 calc(50% - getRem(20));

            }

            @include breakpoint(mobile) {
                //grid-column: span 12;
				flex: 0 0 100%;
            }
        }

        &[data-size="25"] {
            //grid-column: span 3;
			flex: 0 0 calc(25% - getRem(20));

            @include breakpoint(tablet) {
                //grid-column: span 6;
				flex: 0 0 calc(50% - getRem(20));

            }

            @include breakpoint(mobile) {
                //grid-column: span 12;
				flex: 0 0 100%;
            }
        }

        &[data-size="33"] {
            //grid-column: span 4;
			flex: 0 0 calc(33% - getRem(20));

            @include breakpoint(tablet) {
                //grid-column: span 6;
				flex: 0 0 calc(50% - getRem(20));

            }

            @include breakpoint(mobile) {
                //grid-column: span 12;
				flex: 0 0 100%;
            }
        }

        &[data-size="50"] {
            //grid-column: span 6;
			flex: 0 0 calc(50% - getRem(20));

            @include breakpoint(mobile) {
                //grid-column: span 12;
				flex: 0 0 100%;
            }
        }

        &[data-size="66"] {
            //grid-column: span 8;
			flex: 0 0 calc(66% - getRem(20));

            @include breakpoint(tablet) {
				flex: 0 0 calc(50% - getRem(20));

            }

            @include breakpoint(mobile) {
                //grid-column: span 12;
				flex: 0 0 100%;
            }
        }

        &[data-size="75"] {
            //grid-column: span 9;
			flex: 0 0 calc(75% - getRem(20));

            @include breakpoint(tablet) {
				flex: 0 0 calc(50% - getRem(20));
            }

            @include breakpoint(mobile) {
                //grid-column: span 12;
				flex: 0 0 100%;
            }
        }

        &[data-size="80"] {
            //grid-column: span 10;
			flex: 0 0 calc(80% - getRem(20));

            @include breakpoint(tablet) {
				flex: 0 0 calc(50% - getRem(20));

            }

            @include breakpoint(mobile) {
                //grid-column: span 12;
				flex: 0 0 100%;
            }
        }

        &[data-size="100"],
        .divider {
            //grid-column: span 12;
			flex: 0 0 100%;
        }
    }
}

.imageItem {
    h3 {
        @include font(21, 24);
    }

    p {
        font-size: getRem(20);
        line-height: 1.4;
    }
}

.videoContent {
    .thumbnailContainer {
        aspect-ratio: 1.78;
        overflow: hidden;

        span {
            width: 100% !important;
            height: 100% !important;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.thumbnailContainer {
    position: relative;

    .playButton {
        width: 100%;
        height: 100%;
        cursor: pointer;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
    }
}

.columnContent {
    &:first-child {
		flex: 0 0 100%;
        // grid-column: span 12;
    }

    .row {
        //display: grid;
        //grid-template-columns: repeat(2, 1fr);
        //column-gap: getRem(85);
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		gap: 0 getRem(85);
    }

    .textContainer {
        // width: 54.5%;
        display: flex;
        // align-items: center;
        flex-direction: column;
        justify-content: center;
        padding: getRem(36) 0;

        // &:first-child {
        //     padding-right: getRem(85);

        //     @include breakpoint(tablet) {
        //         padding-right: getRem(48);
        //     }
        // }

        // &:last-child {
        //     padding-left: getRem(85);

        //     @include breakpoint(tablet) {
        //         padding-left: getRem(48);
        //     }
        // }

        h2 {
            @include font(32, 40);
            margin: 0;
        }

        p {
            margin-bottom: 0;
        }

        a {
            margin-right: auto;
        }

        .btn {
            margin-top: getRem(32);
        }
    }

    .imageContainer {
        // width: 45.4%;
        height: 100%;
        flex-shrink: 0;

        &.fullImage {
            aspect-ratio: .88;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        img {
            width: 100%;
            height: auto;
        }
    }
}

.textContent,
.ctaContent {
    h3 {
        &:not(:first-child) {
            margin-top: getRem(56);
        }

        &:first-child {
            margin-top: 0;
        }
    }

    p {
        // @include font(21, 41);

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }

        a {
            color: var(--blue);

			&:hover {
				color: var(---blue-dark);
				text-decoration: underline;
			}
        }
    }

    figure {
        margin: 0;
    }
}

.textContent {
    :global {

        .ck-sidebar,
        .secondary-sidebar-text {
            display: block;
            max-width: var(--content-max-width);
            margin: getRem(80) 0 getRem(40);
            position: relative;

            @include breakpoint(desktop) {
                // margin-top: -10rem;
            }

            * {
                color: var(--text-light2);
            }

            &::before {
                content: "";
                position: absolute;
                top: getRem(-25);
                width: getRem(83);
                height: getRem(8);
                background-color: var(--yellow);
                pointer-events: none;
                border-bottom-left-radius: getRem(4);
                border-top-right-radius: getRem(4);
            }

            .video-block {
                margin: 1rem 0;
            }

            blockquote {
                margin: 0;
                padding: 0;

                &::after,
                &::before {
                    content: none;
                    display: none;
                }
            }
        }

        blockquote {
            --content-max-width: #{getRem(600)};
            display: block;
            position: relative;
            padding-top: getRem(46);
            margin-top: getRem(80);
            margin-bottom: getRem(80);
          
            &::before {
              display: block;
              position: absolute;
              content: "";
              width: getRem(160);
              height: getRem(16);
              background-color: var(--yellow);
              border-top-right-radius: getRem(8);
              border-bottom-left-radius: getRem(8);
              top: 0;
              left: 0;
              pointer-events: none;
            }
          }
    }
}

.ctaContent {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.gridContent {
    display: flex;
    flex-wrap: wrap;
    gap: getRem(68) getRem(20);
    justify-content: flex-start;
	width: 100%;
    //display: grid;
    //grid-gap: getRem(68) getRem(20);
    //grid-template-columns: repeat(12, 1fr);
    //grid-column: span 12;

    .gridItem {
        &[data-size="20"] {
            //grid-column: span 2;
			flex: 0 0 calc(20% - getRem(20));

            @include breakpoint(tablet) {
                //grid-column: span 6;
				flex: 0 0 calc(50% - getRem(20));
            }

            @include breakpoint(mobile) {
                //grid-column: span 12;
				flex: 0 0 100%;
            }
        }

        &[data-size="25"] {
           // grid-column: span 3;
			flex: 0 0 calc(25% - getRem(20));

            @include breakpoint(tablet) {
                //grid-column: span 6;
				flex: 0 0 calc(50% - getRem(20));
            }

            @include breakpoint(mobile) {
                //grid-column: span 12;
				flex: 0 0 100%;
            }
        }

        &[data-size="33"] {
            //grid-column: span 4;
			flex: 0 0 calc(33% - getRem(20));

            @include breakpoint(tablet) {
                //grid-column: span 6;
				flex: 0 0 calc(50% - getRem(20));
            }

            @include breakpoint(mobile) {
                //grid-column: span 12;
				flex: 0 0 100%;
            }
        }

        &[data-size="50"] {
            //grid-column: span 6;
			flex: 0 0 calc(50% - getRem(20));

            @include breakpoint(mobile) {
                //grid-column: span 12;
				flex: 0 0 100%;
            }
        }

        &[data-size="66"] {
            //grid-column: span 8;
			flex: 0 0 calc(66% - getRem(20));

            @include breakpoint(tablet) {
                //grid-column: span 6;
				flex: 0 0 calc(50% - getRem(20));
            }

            @include breakpoint(mobile) {
                //grid-column: span 12;
				flex: 0 0 100%;
            }
        }

        &[data-size="75"] {
           // grid-column: span 9;
			flex: 0 0 calc(75% - getRem(20));

            @include breakpoint(tablet) {
                //grid-column: span 6;
				flex: 0 0 calc(50% - getRem(20));
            }

            @include breakpoint(mobile) {
                //grid-column: span 12;
				flex: 0 0 100%;
            }
        }

        &[data-size="80"] {
            //grid-column: span 10;
			flex: 0 0 calc(80% - getRem(20));

            @include breakpoint(tablet) {
                //grid-column: span 6;
				flex: 0 0 calc(50% - getRem(20));
            }

            @include breakpoint(mobile) {
                //grid-column: span 12;
				flex: 0 0 100%;
            }
        }

        &[data-size="100"] {
            //grid-column: span 12;
			flex: 0 0 100%;

            .imageContainer {
                img {
                    width: auto;
                    max-width: 100%;
                }
            }
        }

        .imageContainer {
            width: 100%;
            position: relative;

            img {
                width: 100%;
            }

            .linkText {
                width: 100%;
                height: 100%;
                font-size: getRem(2);
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
            }
        }

        .textContainer {
            h3 {
                @include font(20, 28);
                margin: getRem(12) 0 0;
            }

            p {
                @include font(16, 26);
                margin: getRem(8) 0;
            }
        }
    }
}

.textLink {
    border-bottom: 2px solid transparent;
    color: var(--red);
    display: inline-block;
    @include font(16, 24);
    font-weight: bold;
    margin-top: getRem(12);
    padding: 0;

    &:hover {
        border-bottom: 2px solid var(--red);
    }
}

.gridItem {
    //margin: 0 auto;
}

.soloGridItem {
    img {
        max-width: 100%;
    }
}

.imageItem {
    .imageContainer {
        display: inline-block;
        position: relative;
    }

    button.imageContainer {
        width: 100%;
        background: none;
        border: none;
        cursor: pointer;
        padding: 0;
        position: relative;

        span {
            width: 100%;
            height: 100%;
            font-size: 2px;
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
        }
    }

    a.imageContainer {
        &:after {
            content: '';
            width: 100%;
            height: 100%;
            display: inline-block;
            background-color: rgba(0, 0, 0, .5);
            transition: opacity .15s ease-in 0s;
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
        }

        svg {
            width: getRem(50);
            height: getRem(50);
            transition: opacity .15s ease-in 0s;
            opacity: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(-90deg);
            z-index: 2;
        }


        &:hover,
        &:focus {

            &:after,
            svg {
                opacity: 1;
            }
        }
    }
}

.fileSection {
    width: 100%;

    :global {
        .fileHeader {
            display: flex;
            align-items: center;
            @include font(14, 18);
            font-weight: 700;
            text-transform: uppercase;
            padding: 0 getRem(48);

            svg {
                margin-right: getRem(4);
            }
        }
    }
}

.downloadContainer {
    .imageContainer {
        display: flex;

        &:after {
            content: '';
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, .5);
            display: inline-block;
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            transition: opacity .15s ease-in 0s;
            z-index: 0;
        }

        svg {
            width: getRem(50);
            height: getRem(50);
            opacity: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: opacity .15s ease-in 0s;
            z-index: 1;
        }
    }

    &:hover,
    &:focus {
        .imageContainer {
            &:after {
                opacity: 1;
            }

            svg {
                opacity: 1;
            }
        }
    }
}

.bottom {
    padding: getRem(40) 0;
    display: flex;
    justify-content: space-between;

    .bottomCTA {
        color: var(--text-color);
        display: flex;
        @include font(24, 25);
        font-weight: 600;
        position: relative;

        &:first-child:not(:last-child) {
            svg {
                margin: auto getRem(8) auto 0;
                transform: rotate(180deg);
            }
        }

        &.nextCTA {
            margin-left: auto;
        }

        &:hover {
            text-decoration: underline;
        }

        &:focus {
            border: 1px solid var(--text-color);
        }

        svg {
            width: auto;
            height: getRem(18);
            display: inline-block;
            vertical-align: middle;
            margin: auto 0 auto getRem(8);
        }
    }
}

.fgdPage {
    .textContent+.gridContent {
        margin-top: 0;
    }

    .gridContent {
        grid-gap: getRem(20);
    }
}

.anchorTitle {
    font-weight: 600;
    color: var(--red);
    font-size: .9em;
}

.guidelinesPage .anchorList .anchorItemActive {
    font-weight: 600;
    color: var(--red);
}

.guidelinesPage .anchorList ul ul {
    padding-left: 1rem;
    font-size: .9em;
}

.pagination {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 200px;
    font-weight: 600;
    font-size: 1.25em;
	margin-bottom: 4rem;

    .left {
        margin-right: auto;
    }

    .right {
        margin-left: auto;
    }

    a:hover {
        color: var(--red);
    }
}